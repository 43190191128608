:root {
  --red: #c52830;
  --orange: #e9724c;
  --yellow: #ffc857;
  --green: #519872;
  --blue: #064789;
  --ltBlue: #0496ff;
  --gray: #857f74;
  --ltGray: #f1edee;
  --white: #fffffa;
  --onyx: #353935;
  --fullwidth: 100%;

  --stn-player-titleBarColor: #064789;
  --stn-player-scrollBarColor: #064789;
  --stn-player-selectedPlaylistItemLeftBorderColor: hsl(210, 92%, 28%);
  --stn-player-hoveredPlaylistItemTitleColor: #064789;
} 

.loader {
  position: relative;
  width: 140px;
  height: 200px;
  background-repeat: no-repeat;
  background-image: linear-gradient(#e9724c 100px, transparent 0),
                    linear-gradient(#ffc857 100px, transparent 0),
                    linear-gradient(#519872 100px, transparent 0),
                    linear-gradient(#064789 100px, transparent 0),
                    linear-gradient(#0496ff 100px, transparent 0);
  background-size: 20px 100%;
  background-position: 0px 180px, 30px 156px, 60px 132px, 90px 116px, 120px 100px;
  animation: pillerPushUp 4s linear infinite;
}
.loader:after {
  content: '';
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 16px;
  height: 16px;
  background: #de3500;
  border-radius: 50%;
  animation: ballStepUp 4s linear infinite;
}

@keyframes pillerPushUp {
  0% , 40% , 100%{background-position: 0px 180px, 30px 156px, 60px 132px, 90px 116px, 120px 100px}
  50% ,  90% {background-position: 0px 100px, 30px 116px, 60px 132px, 90px 156px, 120px 180px}
}

@keyframes ballStepUp {
  0% {transform: translate(2px, 0)}
  5% {transform: translate(17px, -28px)}
  10% {transform: translate(32px, -20px)}
  17% {transform: translate(47px, -48px)}
  20% {transform: translate(62px, -40px)}
  27% {transform: translate(77px, -68px)}
  30% {transform: translate(92px, -60px)}
  37% {transform: translate(107px, -88px)}
  40% {transform: translate(122px, -80px)}
  50% {transform: translate(122px, 0)}
  57% {transform: translate(107px, -28px)}
  60% {transform: translate(92px, -20px)}
  67% {transform: translate(77px, -48px)}
  70% {transform: translate(62px, -40px)}
  77% {transform: translate(47px, -68px)}
  80% {transform: translate(32px, -60px)}
  87% {transform: translate(17px, -88px)}
  90% {transform: translate(2px, -80px)}
  100% {transform: translate(2px, 0);}
}

.cardLoader{
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: .5rem;
  row-gap: 2px;
  color: var(--blue);
  background-color: var(--white);
  font-size: 1.1rem;
  justify-content: center;
  align-items: center;
}

.btn-primary {
  color: var(--white);
  background-color: var(--ltBlue);
  border-color: var(--ltBlue);
}

.red{
  background-color: var(--red);
  border-color: var(--red);
}
.orange{
  background-color: var(--orange);
  border-color: var(--orange);
}
.yellow{
  background-color: var(--yellow);
  border-color: var(--yellow);
}
.blue{
  background-color: var(--blue);
  border-color: var(--blue);
}
.green{
  background-color: var(--green);
  border-color: var(--green);
}
.gray{
  background-color: var(--gray);
  border-color: var(--gray);
}
.ltBlue{
  background-color: var(--ltBlue);
  border-color: var(--ltBlue);
}
.ltBlueSearch{
  background-color: var(--ltBlue);
  border-color: var(--ltBlue);
  color: var(--white)
}
.ltGray{
  background-color: var(--ltGray);
  border-color: var(--ltGray);
}
.onyx{
  background-color: var(--onyx);
  border-color: var(--onyx);
}
.goldStar{
  color: goldenrod
}

/* HTML TAGS */
body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: .85rem;
  line-height: 1.25;
  margin: 0;
  padding: 0;
  color: var(--onyx);
  background-color: var(--ltGray);
}

/*chart bullets*/
.customBullets li {
  list-style-type: none;
  vertical-align: middle;  
  line-height: 1.25rem;
  font-size: .75rem;
  padding:0;
  position: relative; 
}

.customBullets li::before {
  content: '\2022';
  position: absolute;
  left: -1rem;
  bottom: .1rem;
  font-size: 1.5rem; 
}

/* LAYOUT */
.App {
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  width: 100%;
  justify-content: center;
  background-color: var(--ltGray);
  z-index: 3;
}

.AppMask{
  position:absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--ltGray);
  z-index: 2;
}

.Header{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width:100%;
  height: 50px;
  position: sticky;
  top: 0px;
  background-color: var(--ltBlue);
  color: var(--white);
  z-index: 10;
  margin-bottom: 5px;
}

.Footer{
  display: flex;
  flex-direction: column;
  height: 50px;
  position: fixed;
  bottom: 0px;
  width: 100%;
  max-width:1280px;
  background-color: var(--ltBlue);
  margin-top: 10px;
  color: var(--white);
  z-index: 10;
}

.LeftRight{
  display: flex;
  column-gap:.5rem;
}

.Main {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  column-gap: .5rem;
  row-gap: 2px;
  min-height: 1000px;
}

.MainLeft {
  display:flex;
  flex-direction: column;
}

.MainRight {
  display:flex;
  flex-direction: column;
  justify-content: right;
}

.Middle{
  display: flex;
  flex-direction: row;
  min-height: 500px;
  border-radius: .25rem;
  column-gap: .3rem;
  row-gap: .3rem;
  z-index: 6;
  margin-bottom: 4rem;
}

/* RESPONSIVE */

@media screen and (max-width: 500px) {

  :root{--stn-player-floatZIndex: 1;}

  .body{
    scrollbar-width: none;
  }
  .App{
    width: 98%
  }

  .Footer{
    width: 98%
  }

  .LeftRight{
    flex-direction: column;
  }

  .Main {
    margin-left: 0px;
    width: 100%;
    margin-top: 45px;
  }

  .MainLeft{
    order:2; 
  }

  .MainRight{
    order: 1;
    padding-bottom: 5px;
  }

  .NavLeft {
    flex-direction: row;
    top: 50px;
    width: 98%;
    height: 50px;
    padding: 5px 0px 5px 0px;
    background-color: var(--ltGray);
    margin-bottom: 5px;
  }

  .audioButton{
    margin-right: 2px;
  }
  
  .audioPlayer{
    padding: 0px 2px 0px 2px;
  }

  .featureImg{
    width: 300px;
  }

  .featureImgHolder{
    width: 300px;
  }

  .featureRow{
    flex-direction: column;
    max-width: 300px;
  }

  .featureTextHolder{
    width: 300px;
  }

  .navButton{
    margin: 0px 2px 0px 0px;
  }

  .profileImg{
    width: 30px;
    height: 30px;
  }
}

@media screen and (min-width: 500px) {
  .LeftRight{
    flex-direction: column;
  }

  .Main {
    margin-left: 45px;
    width: calc(100% - 45px);
  }

  .MainLeft{
    order:2; 
  }

  .MainRight{
    order: 1;
    padding-bottom: 5px;
  }

  .NavLeft {
    flex-direction: column;
  }

  .featureImg{
    width: 400px;
  }

  .featureImgHolder{
    width: 400px;
  }

  .featureRow{
    flex-direction: column;
  }

  .featureTextHolder{
    padding: 10px;
  }

  .navButton{
    margin: 0px 0px 2px 0px;
  }

  .profileImg{
    width: 30px;
    height: 30px;
  }
}

@media screen and (min-width: 975px) {
  .LeftRight{
    flex-direction: row;
  }

  .Main {
    margin-left: 45px;
    width: calc(100% - 45px);
  }

  .MainLeft{
    order:1;
    width: 60%;
  }

  .MainRight{
    order: 2;
    width: 40%;
  }

  .NavLeft {
    flex-direction: column;
    top: 55px;
    height: 100%;
    width: 40px;
    flex: 0 0 40px;
  }

  .audioButton{
    margin-right: 5px;
  }

  .audioPlayer{
    padding: 0px 5px 0px 5px;
  }

  .featureImg{
    width: 400px;
  }

  .featureImgHolder{
    width: 400px;
  }

  .featureRow{
    flex-direction: row;
  }

  .featureTextHolder{
    padding: 10px;
  }

  .navButton{
    margin: 0px 0px 2px 0px;
  }

  .profileImg{
    width: 40px;
    height: 40px;
  }
} 

/* COMPONENTS */
.ActivityView {
  justify-content: flex-start;
}

.ErrorBoundary {
  padding-top: 100px;
}

.Headline {
  display: flex;
  width:100%;
  min-height: 60px;
  border-radius: .25rem;
  background-color: var(--yellow);
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}


.NavCharts {
  display: flex;
  margin-top: .25rem;
  margin-bottom: .25rem;
  height: 2.5rem;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: .25rem;
  align-items: flex-end;
  padding-left: 0px;
  padding-right: 0px;
  z-index: 9;
}

.NavLeft {
  position: fixed;
  display: flex;
  align-items: center;
  padding-left: 0px;
  padding-right: 0px;
  z-index: 10;
}

.NavLogo {
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

/* NAMED CLASSES */

.adContainerRight{
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border: 1px 1px 0px 0px solid var(--blue);
  border-radius: .25rem;
  margin-bottom: .25rem;
  color: var(--blue);
  box-shadow: 3px 3px 2px var(--gray);
  background-color: var(--white);
}

.accordionButton{
  display: flex;
  width: 100%;
  color: var(--blue);
  background-color: var(--white);
  border-color: var(--ltGray);
  vertical-align: middle;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 3px 3px 2px var(--gray);
  margin-bottom: 5px;
  padding: 10px 20px 10px 20px;
  border-radius: .25rem;
}

.activityButton {
  display: flex;
  width: 2rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  padding: 0px ;
  margin: 0px 0px 2px 0px;
  border-radius: .25rem;
}

.activityButtonText{
  font-size: 0.9rem;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}

.actionButtons{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: .25rem;
  align-items: center;
}

.adContainerStream{
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border: 1px 1px 0px 0px solid var(--blue);
  border-radius: .25rem;
  margin-bottom: .5rem;
  row-gap: 2px;
  color: var(--blue);
  box-shadow: 3px 3px 2px var(--gray);
  background-color: var(--white);
}

.adContainerHeadline{
  display: flex;
  flex-direction: column;
  width: 90%;
  min-height: 110px;
  margin: 0.5rem;
  border: 1px 1px 0px 0px solid var(--blue);
  border-radius: .25rem;
  row-gap: 2px;
  box-shadow: 3px 3px 2px var(--gray);
  background-color: var(--white);
  justify-content: center;
  align-items: center;
  align-self: center;
}

.adminButtons{
  align-items: end;
}

.audioButton{
  display:flex;
  align-items: center;
  background-color: var(--ltBlue);
  color: var(--white) !important;
  border: none;
  cursor:pointer;
  overflow: hidden;
  outline:none;
}

.audioButtonPlay{
  font-size: 18px;
}

.audioButtonMute{
  font-size: 24px;
}

.audioControl {
  display:flex;
  flex-direction: row;
  align-items: center;
}

.audioPlayer{
  background-color: var(--ltBlue);
  min-height: 2.5rem;
  width:100%;
  border-radius: .25rem;
  margin-top: .25rem; 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.avatar{
  border-radius: 40px;
  text-align: center;
  vertical-align: middle;
  color:#fffffa;
  font-size: 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.badge {
  /* circle shape, size and position */
  position: absolute;
  z-index: 5;
  right: -2px;
  top: -2px;
  height: 15px;
  width: 15px;
  border-radius: 8px; 
  border: 0.05em solid white;
  background-color: red;

  /* number size and position */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.5rem;
  color: white;
}

.cardColumn{
  display: flex;
  flex-direction: column;
}

.cardContainer{
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border: 1px 1px 0px 0px solid var(--blue);
  border-radius: .25rem;
  margin-bottom: .5rem;
  row-gap: 2px;
  color: var(--blue);
  box-shadow: 3px 3px 2px var(--gray);
  background-color: var(--white);
}

.cardContainerGray{
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border: 1px 1px 0px 0px solid var(--blue);
  border-radius: .25rem;
  margin-bottom: .5rem;
  row-gap: 2px;
  color: var(--blue);
  background-color: var(--ltGray);
}

.cardRow{
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: .5rem;
  align-items: flex-start;
}

.cardRowTopAlign{
  display: flex;
  align-items: flex-start;
  vertical-align: top;
}

.cardRowSpaced{
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 3px;
}

.cardHeader{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.cardSanitized{
  width: 100%;
}

.cardSanitized p {
  margin-bottom:0rem;
}

.cardLeft{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  column-gap: 2px;
}

.cardLeftRedText{
  color: #c52830;
  padding-left: 15px;
  text-indent: -15px;
  text-align:left;
}

.cardRight{
  display: flex;
  flex-direction: column;
  column-gap: .5rem;
  float: inline-end;
  flex-grow: 1;
}

.cardImg {
  padding: 5px 5px 5px 0px;
  height:80px;
  width:80px;
  border-radius: 8px;
  text-align: center;
  border: none;
  color: var(--white);
}

.cardImgLg {
  padding: 5px 5px 5px 0px;
  display: flex;
  align-self: center;
  width: 100%;
  max-width: 400px;
  border-radius: 50px;
  text-align: center;
  border: none;
  color: var(--white);
}

.cardImgSm {
  height:45px;
  width:45px;
  border-radius: 8px;
  text-align: center;
  border: none;
  color: var(--white);
}

.cardSubtitle{
  padding: 0px;
  font-weight:900;
  color: var(--red);
  width: 98%;
  font-size: 1.00rem;
}

.cardTitle{
  display:flex;
  flex-direction: row;
  padding: 0px;
  color: var(--blue);
  font-size: 1.25rem;
}

.cardQuestion{
  padding: 0px;
  color: var(--red);
  font-weight:900;
  font-size: 1.0rem;
}

.cardResponse{
  display: flex;
  padding: 0px;
  color: var(--blue);
  font-size: 1.1rem;
  justify-content: center;
  text-align: center;
}

.cardMuted{
  padding: 0px;
  color: var(--gray);
}

.cardMutedCenter{
  padding: 0px;
  color: var(--gray);
  align-items: center;
  text-align: center;
}

.cardPill{
  display:flex;
  z-index: 20;
  min-width: 4rem;
  margin-bottom: .1rem;
  margin: .1rem;
  height: 1.6em;
  max-width: 40px;
  border-radius: 0.8em;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  color: white;
  font-weight: bold;
}

.cardUpperRight{
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 0 1 auto
}

.chartContainer{
  width: 100%;
  height: 250px;
}

.cardNoShadowContainer{
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  border: 1px 1px 0px 0px solid var(--blue);
  border-radius: .25rem;
  margin-bottom: .5rem;
  row-gap: 2px;
  color: var(--blue);
  background-color: var(--white);
}

.chartBoldRedNoWrap{
  color: #c52830;
  white-space: nowrap;
}

.chartRow{
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: .5rem;
  align-items: flex-start;
}

.colorPicker{
  width: 1.5rem;
  height: 1.5rem;
  border-radius: .5rem;
  margin: 5px;
  cursor: pointer;
}
.colorPicker:active, 
.colorPicker:focus 
 {
     border:0.5em solid #857f74; /* here configure as your needs */
     color:#857f74;
  }

.comment{
  background-color: #f1edee99;
  padding: .25rem;
  margin-top: .25rem;
  margin-bottom: .25rem;
  border-radius: .25rem;
  width: 100%;
  box-shadow: 3px 3px 2px var(--gray);
}

.commentContainer{
    width:100%;
    border-radius: .25rem;
    margin-top: .25rem;
    margin-bottom: .1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.commentForm{
  display:flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  column-gap: 1rem;
}

.commentReply{
  display: flex;
  flex-direction: row;
  column-gap: .5rem;
  background-color: var(--white);
  padding: .25rem;
  margin-left: 2rem;
  margin-right: .5rem;
  margin-top: .25rem;
  margin-bottom: .25rem;
  border-radius: .25rem;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 3px 3px 2px var(--gray);
}

.commentShare{
  display: flex;
  line-height: 1.0;
  font-size: inherit;
  margin-top: .1rem;
  padding: .25rem;
  align-self: flex-end;
  justify-content: flex-end;
}

.commentEdit{
  float: right;
}

.cropper{
  position: relative;
  width: 100%;
  background: #f1edee;
}

.expandButton{
  font-size: 1.25rem;
  line-height: 1.25rem;
  outline: none;
  border: none;
}

.features{
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-bottom: 2rem;
  color: var(--blue);
  background-color: var(--ltGray);
}

.featureImg{
  display: flex;
  align-self: center;
  background-color: var(--white);
}

.featureImgHolder{
  padding: 10px 0px 10px 0px;
}

.featureRow{
  display: flex;
  column-gap: 20px;
}

.featureText{
  text-align: center;
}

.featureTextHolder{
  display: flex;
  flex-direction: column;
  max-width: 400px;
  padding: 10px;
  justify-content: center;
}

.featureTitle{
  font-size: 1.5rem;
  color: var(--red);
  text-align: center;
  padding-bottom: 10px;
}

.floatRight{
  position: absolute;
  top: 5px;
  right: 5px;
}

.footerRow{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  line-height: .75;
}

.formContainer{
  display: flex;
  flex-direction: column;
  border: 1px solid var(--blue);
  border-radius: .25rem;
  padding: .5rem;
  row-gap: 2px;
  color: var(--blue);
  background-color: var(--white);
  width: 100%;
}

.formErrors{
  color: var(--red);
  font-size: smaller;
}

.formTitle{
  font-weight: 800;
  color: var(--red)
}

.formSubmit{
  float:right;
  margin-top: .5rem;
  margin-bottom:.5rem;
  min-width: 100px;
}

.floatingLabel {
  transform-origin: 0 0;
  scale: .85;
  color: var(--blue);
  opacity: .65;
  transform: scale(.85) translateY(-.5rem) translateX(.15rem);
  margin-bottom: 0;
}

.infoBox{
  min-height: 2rem;
  background-color:#fffffa;
  padding: 9px 12px;
  border: 1px solid #ccc;
  justify-content: flex-start;
  font-size: 0.75rem;
  display:flex;
  flex-direction: row;
  float:right;
  align-self: flex-end;
  z-index: 10;
  transition: width 2s;
}

.linkContainer{
  width: 100%;
  height:auto;
  cursor: pointer;
  padding-bottom: .2rem;
}

.logoImg {
  padding: .3rem;
  height: 3rem;
  width: 3rem;
}

.logoText{
  font-weight: 900;
  align-self: center;
  margin: 0;
  font-size: 1.5rem;
}

.navButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  min-height: 2.5rem;
  min-width: 2.5rem;
  color: var(--white);
  font-size: 1.25rem;
  padding: 0px ;
  border-radius: .25rem;
}

.navLink {
  height: 1.5rem;
  font-size: 0.8rem;
  text-align: center;
  vertical-align: middle;
  padding: 2px 10px 2px 10px;
  margin: 0px;
  border-radius: .25rem;
  text-decoration: none;
  color: var(--white);
  font-weight: 900;
}

.navGroup {
  display:flex;
  align-items: center;
  align-self: flex-end;
  height: 55px;
  margin-right: 0.5rem;
}

.notification{
  border-top: 1px solid var(--gray);
  border-bottom: 1px solid var(--gray);
  padding-top: 5px;
  padding-bottom: 5px;
}

.profileImg {
  display: flex;
  position: relative;
  padding: 0px;
  border-radius: 20px;
  text-align: center;
  align-items:center;
  justify-content: center;
  font-size: 1.25rem;
  border: none;
  color: var(--white);
  background-color: var(--blue);
  border-color: var(--blue);
}

.question{
  color: var(--red);
  min-height: 2.5rem;
  width:100%;
  border-radius: .25rem;
  margin-top: .25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.question-msg{
  color: var(--red);
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
 
.rater{
  min-height: 2.5rem;
  padding: .5rem;
  width:100%;
  border-radius: .25rem;
  margin-top: .25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.scrollable{
  overflow-y: scroll;
  max-height: 700px;
}

.single-line{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.statBox{
  background-color: var(--ltBlue);
  color: var(--white);
  padding: .5rem;
  min-height: 100px;
  cursor: pointer;
  box-shadow: 3px 3px 2px var(--gray);
}

.terms{
  display: flex;
  flex-direction: column;
  padding: 1rem 4rem 1rem 4rem;
  margin-bottom: 2rem;
  color: var(--blue);
  background-color: var(--white);
}


.textLink {
  text-align: right;
  vertical-align: middle;
  text-decoration: none;
  color: var(--ltBlue);
  float: left;
}


.transparent {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem;
  padding: 0px ;
  margin: 0 0 0 .25rem;
  align-items: center;
  justify-content: center;
  border-radius: .25rem;
  background-color: transparent;
  color: var(--blue) !important;
  border: none;
  cursor:pointer;
  overflow: hidden;
  outline:none;
}

.transparentSmall {
  width: .75rem;
  height: .75rem;
  font-size: 0.75rem;
  padding: 0px ;
  margin: 0 0 0 .25rem;
  align-items: center;
  justify-content: center;
  border-radius: .25rem;
  background-color: transparent;
  color: var(--blue) !important;
  border: none;
  cursor:pointer;
  overflow: hidden;
  outline:none;
}

.unselectable {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.xsbar{
  height: 20px; 
  border-radius: 5px;
  background-color: #0496ff; 
  opacity: 0.6
}

/* .custom-control-label {
  margin-left: .25rem;
} */

/* NAMED IDs */
#root {
  display: flex;
  background-color: var(--ltGray);
  justify-content: center;
}



